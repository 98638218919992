import {
    UniversalDataTable,
    UniversalModal,
} from '../../../../UniversalComponents'
import { useEffect, useReducer, useRef } from 'react'
import { Button, Grid } from '@mui/material'
import { AddBox } from '@mui/icons-material'
import EngineCalibrationForm from './EngineCalibrationForm'
import { standardColumnMapper } from '../../../../../utilities/functionTools'
const EngineCalibration = ({
    activeEngineCalibrationFile,
    engineCalibrationFiles,
    addCalibrationFile,
}) => {
    const defaultState = {
        row: {},
        density: 'standard',
        open: false,
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { density, open, row } = state

    const defaultColumns = [
        'engineCalibrationECMPartNumber',
        'engineCalibrationProductionDate',
        'engineCalibrationRevision',
        'engineCalibrationSoftwareModel',
        'engineCalibrationEControlsSoftwareRevision',
        'engineCalibrationFileName',
        'engineCalibrationReleaseNotes',
        'engineCalibrationChecksum',
        'engineCalibrationApproved',
        'engineCalibrationReviewers',
        'engineCalibrationActive',
        'updatedBy',
        'updatedAt',
        'createdAt',
    ]
    const parsedEngineCalibrationFiles = standardColumnMapper(
        defaultColumns,
        engineCalibrationFiles
    )

    const visibleColumns = [
        'engineCalibrationECMPartNumber',
        'engineCalibrationProductionDate',
        'engineCalibrationRevision',
        'engineCalibrationSoftwareModel',
        'engineCalibrationEControlsSoftwareRevision',
        'engineCalibrationFileName',
        'engineCalibrationReleaseNotes',
        'engineCalibrationChecksum',
        'engineCalibrationActive',
    ]

    const customColumnNames = [
        'ECM PN',
        'PROD DATE',
        'REV',
        'SW MODEL',
        'ECONTROLS SW REV',
        'EMOT FILE NAME',
        'RELEASE NOTES',
        'CHECKSUM',
        'ACTIVE',
    ]

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
        })
    }

    const setModalVisbility = async (tableRow: any) => {
        if (tableRow?.engineCalibrationECMPartNumber) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBox />}
                    onClick={() => setState({ open: true })}
                    fullWidth
                    sx={{
                        height: 55,
                    }}
                >
                    Add Calibration File
                </Button>
            </Grid>
            <Grid item xs={12}>
                {engineCalibrationFiles &&
                    engineCalibrationFiles.length !== 0 && (
                        <UniversalDataTable
                            data={parsedEngineCalibrationFiles}
                            sx={{ marginTop: -2 }}
                            apiDensity={density}
                            visibleColumns={visibleColumns}
                            customColumnNames={customColumnNames}
                            getRowData={(tableRow) =>
                                setModalVisbility(tableRow)
                            }
                            onDensityChange={handleDensityChange}
                            checkboxSelection={false}
                        />
                    )}
            </Grid>
            <UniversalModal
                title={`${
                    row.id
                        ? `Engine Hardware Part Number : ${row.engineHardwarePartNumber}`
                        : 'Configure A New Engine'
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <EngineCalibrationForm
                    row={row}
                    onClose={(tableRow) => setModalVisbility(tableRow)}
                    quickStart={false}
                    addCalibrationFile={addCalibrationFile}
                />
            </UniversalModal>
        </Grid>
    )
}

export default EngineCalibration
