import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    addEngine,
    getManufacturers,
    getEngineModels,
} from '../../../../apiRoutes'
import { getEngineCalibrationFiles } from '../../../../apiRoutes'
import { useMinimizer } from '../../../../context/MinimizeContext'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { FormSection } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
import { UniversalAutoComplete } from '../../../UniversalComponents/UniversalFormStyles'
import EngineCalibration from './EngineCalibration'
import { AddBox } from '@mui/icons-material'
const EngineManagerForm = ({
    row,
    onClose,
    saveCache,
    permissionCheck,
    quickStart,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)

    const { userRole, activeManufacturer } = currentUser.auth
    const defaultState = {
        formData: {
            engineName: row?.engineName || '',
            engineHardwarePartNumber: row?.engineHardwarePartNumber || '',
            engineDescription: row?.engineDescription || '',
            engineCalibrationFiles: row?.engineCalibrationFiles || [],
            activeEngineCalibrationFile: row?.activeEngineCalibrationFile || {},
            updatedBy: row?.updatedBy || '',
            id: row.id || '',
            manufacturer: row?.id
                ? row.manufacturer?.manufacturerName
                : activeManufacturer,
        },
        formValidation: {
            engineSerialValidation: false,
            hardwarePartNumber: false,
            manufacturerValidation: false,
            engineHardwarePartNumberValidation: false,
            calibrationValidation: false,
            engineNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        newEngineCalibrationFiles: [],
        closeModal: false,
        manufacturers: [],
        manufacturerOptions: [],
        formCache: {},
        permissionsCheck: permissionCheck,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        manufacturerOptions,
        manufacturers,
        newEngineCalibrationFiles,
    } = state

    const {
        engineName,
        engineDescription,
        engineHardwarePartNumber,
        manufacturer,
        engineCalibrationFiles,
        activeEngineCalibrationFile,
    } = formData

    const {
        manufacturerValidation,
        engineSerialValidation,
        engineNameValidation,
        engineHardwarePartNumberValidation,
        calibrationValidation,
        hardwarePartNumberValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleSubmit = async () => {
        try {
            const findManufacturer = manufacturers.find(
                ({ manufacturerName }) =>
                    manufacturerName === formData.manufacturer
            )

            const parsedFormData = {
                ...formData,
                manufacturer: findManufacturer,
            }

            let res: any

            res = await addEngine(parsedFormData)
            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Engine: ${data.engineName} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    onClose(row.engineName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })

        saveCache({ ...formData, [name]: value })
    }

    const getData = async () => {
        let manufacturers: any = []
        let manufacturerOptions: any = []

        try {
            const getManufacturerData = await getManufacturers(true)
            if (getManufacturerData && getManufacturerData.length !== 0) {
                manufacturers = getManufacturerData.filter(
                    (manufacturer) => manufacturer.manufacturerType === 'engine'
                )
                manufacturerOptions = manufacturers.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }

            setState({
                manufacturers,
                manufacturerOptions,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const addCalibrationFile = (calibrationFormData, files) => {
        const newEngineCalibrations = (
            oldCalibrationFormData,
            newCalibrationFormData
        ) => {
            const index = oldCalibrationFormData.findIndex(
                (calibrationFile) =>
                    calibrationFile._id === newCalibrationFormData.id
            )

            if (index !== -1) {
                oldCalibrationFormData[index] = newCalibrationFormData
            } else {
                oldCalibrationFormData.push(newCalibrationFormData)
            }

            return oldCalibrationFormData
        }

        const newEngineCalibrationFormData = newEngineCalibrations(
            engineCalibrationFiles,
            calibrationFormData
        )

        if (engineCalibrationFiles && engineCalibrationFiles.length !== 0) {
            if (
                calibrationFormData &&
                calibrationFormData.engineCalibrationActive
            ) {
                const newCalibrations = newEngineCalibrationFormData.map(
                    (file: any) => {
                        if (
                            file.engineCalibrationECMPartNumber !==
                            calibrationFormData.engineCalibrationECMPartNumber
                        ) {
                            return { ...file, engineCalibrationActive: false }
                        } else {
                            return file
                        }
                    }
                )
                setState({
                    formData: {
                        ...formData,
                        activeEngineCalibrationFile: calibrationFormData,
                        engineCalibrationFiles: newCalibrations,
                    },
                    newEngineCalibrationFiles: [
                        ...newEngineCalibrationFiles,
                        files,
                    ],
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            } else {
                setState({
                    formData: {
                        ...formData,
                        engineCalibrationFiles: newEngineCalibrationFormData,
                    },
                    newEngineCalibrationFiles: [
                        ...newEngineCalibrationFiles,
                        files,
                    ],
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            }
        } else {
            setState({
                formData: {
                    ...formData,
                    activeEngineCalibrationFile: calibrationFormData,
                    engineCalibrationFiles: newEngineCalibrationFormData,
                },
                newEngineCalibrationFiles: [
                    ...newEngineCalibrationFiles,
                    files,
                ],
                formValidation: { ...formValidation, isFormDirty: true },
            })
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !engineHardwarePartNumberValidation &&
            !manufacturerValidation &&
            !engineNameValidation &&
            !calibrationValidation &&
            engineHardwarePartNumber &&
            manufacturer &&
            engineName &&
            engineCalibrationFiles
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerValidation,
        engineNameValidation,
        engineHardwarePartNumberValidation,
        calibrationValidation,
        hardwarePartNumberValidation,
        manufacturer,
        engineName,
        engineHardwarePartNumber,
        engineCalibrationFiles,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <UniversalTagToolbar />
            </Grid>
            <Grid item xs={12}>
                <FormSection title="Engine Details">
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            disablePortal
                            id="manufacturer"
                            options={manufacturerOptions}
                            value={manufacturer}
                            fullWidth
                            disabled={true}
                            onChange={(event, newValue) => {
                                const data = {
                                    target: {
                                        name: 'manufacturer',
                                        value: newValue,
                                    },
                                }
                                handleChange(data)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Engine Manufacturer"
                                    name="manufacturer"
                                    onChange={handleChange}
                                    required
                                    disabled={
                                        permissionCheck ||
                                        userRole !== 'Super Admin'
                                    }
                                    value={manufacturer}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={manufacturerValidation}
                                    helperText={
                                        manufacturerValidation
                                            ? 'Please enter a manufacturer name.'
                                            : ''
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            required={true}
                            fullWidth
                            label="Engine Name"
                            name="engineName"
                            onChange={handleChange}
                            value={engineName}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={engineNameValidation}
                            helperText={
                                engineNameValidation
                                    ? 'Please enter a Engine Name.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required={true}
                            fullWidth
                            label="Engine Description"
                            name="engineDescription"
                            onChange={handleChange}
                            value={engineDescription}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={engineSerialValidation}
                            helperText={
                                engineSerialValidation
                                    ? 'Please enter an Engine Description.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            required={true}
                            fullWidth
                            label="Engine Hardware Part Number"
                            name="engineHardwarePartNumber"
                            onChange={handleChange}
                            value={engineHardwarePartNumber}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={engineHardwarePartNumberValidation}
                            helperText={
                                engineHardwarePartNumberValidation
                                    ? 'Please enter a hardware part number.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <FormSection title="Engine Calibration">
                    <Grid item xs={12}>
                        <EngineCalibration
                            engineCalibrationFiles={engineCalibrationFiles}
                            activeEngineCalibrationFile={
                                activeEngineCalibrationFile
                            }
                            addCalibrationFile={addCalibrationFile}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={12}>
                <FormSection
                    title="Required Fields Are Marked With An *"
                    titleAlignment="center"
                >
                    <Grid item xs={7}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!isFormDirty}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            {!quickStart
                                ? 'Save & Close'
                                : 'Submit and go to Step 2'}
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleReset}
                            disabled={!isFormDirty}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    )
}

export default EngineManagerForm
