import { useReducer, useState, useEffect, useContext, useCallback } from 'react'
import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalLoadingCircle,
    UniversalCookieCrumb,
    DataGridLayout,
    FormSection,
} from '../../UniversalComponents'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { Grid, Typography } from '@mui/material'
import EngineManagerForm from './EngineManagerForm'
import {
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    AddToQueue as AddToQueueIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import {
    getEngines,
    deactivateEngines,
    deleteEngines,
} from '../../../apiRoutes'
import { useMinimizer } from '../../../context/MinimizeContext'
import { usePermissions, useAuth } from '../../../context/AuthenticationContext'
import {
    permissionsCheck,
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../utilities/functionTools'

const Engines = () => {
    const setToastMessage = useContext(ToastMessage)
    const userPermissions = usePermissions()
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: false,
        row: {},
        data: [],
        viewDeactivated: false,
        formCache: {},
        activateData: [],
        density:
            userPreferences?.dataTableColumns?.devices?.density || 'standard',
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        data,
        activateData,
        viewDeactivated,
        formCache,
        devices,
        density,
    } = state

    const defaultColumns = [
        'engineHardwarePartNumber',
        'engineName',
        'engineDescription',
        'updatedBy',
        'manufacturer.manufacturerName',
        'activeEngineCalibrationFile.engineCalibrationFileName',
    ]

    const getData = async (active) => {
        try {
            const data = await getEngines(active)

            if (data && data.length !== 0) {
                const engines = standardColumnMapper(defaultColumns, data)

                setState({ data: engines })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'engineName',
            setState,
            getData,
            route: deactivateEngines,
            viewDeactivated,
            setToastMessage,
            page: 'engines',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'engineName',
            setState,
            getData,
            route: deleteEngines,
            viewDeactivated,
            setToastMessage,
            page: 'engines',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Devices?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
    }, [])

    // useEffect(() => {
    //     console.log('DataGrid component mounted')
    //     return () => {
    //         submitColumnData()
    //         console.log('DataGrid component unmounted')
    //     }
    // }, []) this might be added later as a quality of life adjustment for userface ease

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const visibleColumns = [
        'engineHardwarePartNumber',
        'engineName',
        'engineDescription',
        'updatedBy',
        'manufacturerName',
        'engineCalibrationFileName',
    ]

    const customColumnNames = [
        'Hardware PN',
        'Engine Name',
        'Description',
        'Updated By',
        'Manufacturer',
        'Active Calibration File',
    ]

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.id) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const buttons = [
        {
            buttonName: 'Add New Engine',
            buttonIcon: <AddToQueueIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Engines'
                : 'View Deactivated Engines',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            devices: {
                ...devices,
                density: newDensity,
            },
        })
    }

    return (
        <Grid container spacing={2}>
            <DataGridLayout
                cookieCrumbs={<UniversalCookieCrumb />}
                toolbar={<UniversalToolBar buttons={buttons} />}
                tabBar={
                    <FormSection>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                color={'primary'}
                                align="center"
                                sx={{ marginTop: -1 }}
                            >
                                {!viewDeactivated
                                    ? 'Engines'
                                    : 'Deactivated Engines'}
                            </Typography>
                        </Grid>
                    </FormSection>
                }
                dataGrid={
                    <UniversalLoadingCircle
                        data={data}
                        reloadFunction={!viewDeactivated ? getData : () => {}}
                        customTimeoutMessage={
                            !!viewDeactivated &&
                            'No Deactivated Engines Available'
                        }
                    >
                        <UniversalDataTable
                            data={data}
                            apiDensity={density}
                            visibleColumns={visibleColumns}
                            customColumnNames={customColumnNames}
                            getRowData={(tableRow) =>
                                setModalVisbility(tableRow, undefined)
                            }
                            onRowSelectionModelChange={(newSelection) => {
                                setState({
                                    activateData: newSelection,
                                })
                            }}
                            onDensityChange={handleDensityChange}
                        />
                    </UniversalLoadingCircle>
                }
            />
            <UniversalModal
                title={`${
                    row.engineHardwarePartNumber
                        ? `Engine Hardware Part Number : ${row.engineHardwarePartNumber}`
                        : 'Configure A New Engine'
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <EngineManagerForm
                    row={row}
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Engines'
                    )}
                    quickStart={false}
                />
            </UniversalModal>
        </Grid>
    )
}

export default Engines
