import { useReducer, useRef, useEffect } from 'react'
import { useSocket } from '../../context/SocketContext'
import {
    FormSection,
    UniversalDataTable,
    UniversalGoogleMap,
} from '../UniversalComponents'
import { Grid, TextField, Button, Typography } from '@mui/material'
import { AddBox, Bolt } from '@mui/icons-material'
import {
    SleepingPieChart,
    ModelsBarGraph,
    ManufacturersChart,
    EngineHoursHistogram,
} from './ChartJsGraphics'
import { useQuickStartWizard } from '../../context/QuickStartWizard'
import { useAuth } from '../../context/AuthenticationContext'
import Manufacturer from '../Admin/Manufacturers'

const Home = () => {
    const defaultState = {
        telemetry: [],
        fleetGps: [],
        fleetTable: [],
    }
    const currentUser = useAuth()
    const { activeManufacturer, user, userRole } = currentUser.auth
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const mapFleetTelemetry = (telemetry: any) =>
        telemetry
            .map((item) => {
                if (
                    item &&
                    item.redisObj &&
                    item.redisObj.GPS_Latitude &&
                    item.redisObj.GPS_Longitude
                ) {
                    return {
                        lat: parseFloat(item.redisObj.GPS_Latitude),
                        lng: parseFloat(item.redisObj.GPS_Longitude),
                        name: item._doc.fullHin,
                    }
                }
            })
            .filter((item) => item !== undefined)
    const mapFleetTableTelemetry = (telemetry: any) =>
        telemetry
            .map((item) => ({
                id: item._doc._id,
                fullHin: item._doc.fullHin,
                nickname: item._doc.nickname,
                manufacturer: item._doc.manufacturer.manufacturerName,
                status: item.redisObj._boatStatus || 'NOT CONNECTED',
                lastConnected: new Date(
                    item.redisObj._TS_Last_Logged
                ).toDateString(),
            }))
            .filter((item) => item !== undefined)

    const { telemetry, fleetGps, fleetTable } = state

    const { socket } = useSocket()

    const refreshCount = useRef(0)
    const interval = useRef<any>()

    useEffect(() => {
        socket.emit('allFleetLiveData', { user })
        socket.on('allFleetLiveDataRes', (data) => {
            if (data) {
                const filteredTelemetry = data.filter(
                    (obj: any) => obj.redisObj !== 'noRedisObj'
                )
                setState({
                    telemetry: filteredTelemetry,
                    fleetGps: mapFleetTelemetry(filteredTelemetry),
                    fleetTable: mapFleetTableTelemetry(filteredTelemetry),
                })
            }
            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [])

    useEffect(() => {
        socket.emit('allFleetLiveData', { user })
        socket.on('allFleetLiveDataRes', (data) => {
            if (data) {
                const filteredTelemetry = data.filter(
                    (obj: any) => obj.redisObj !== 'noRedisObj'
                )
                setState({
                    telemetry: filteredTelemetry,
                    fleetGps: mapFleetTelemetry(filteredTelemetry),
                    fleetTable: mapFleetTableTelemetry(filteredTelemetry),
                })
            }
            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [activeManufacturer])

    useEffect(() => {
        interval.current = setInterval(() => {
            socket.emit('allFleetLiveData', { user })
            socket.on('allFleetLiveDataRes', (data) => {
                if (data) {
                    const filteredTelemetry = data.filter(
                        (obj: any) => obj.redisObj !== 'noRedisObj'
                    )
                    setState({
                        telemetry: filteredTelemetry,
                        fleetGps: mapFleetTelemetry(filteredTelemetry),
                        fleetTable: mapFleetTableTelemetry(filteredTelemetry),
                    })
                }
                refreshCount.current = refreshCount.current + 1
                return refreshCount
            })

            return () => socket.emit('leave:room', '')
        }, 10000)

        return () => {
            clearInterval(interval.current)
        }
    }, [interval, activeManufacturer])
    const style = {
        width: '100%',
        height: '600px',
        backgroundColor: 'inherit',
    }
    const visibleColumns = [
        'fullHin',
        'nickname',
        'manufacturer',
        'status',
        'lastConnected',
    ]
    const customColumnNames = [
        'HIN',
        'Boat Nickname',
        'Manufacturer',
        'Status',
        'Last Connected',
    ]

    return (
        <Grid container spacing={1} sx={{ marginTop: 10 }}>
            <Grid item xs={12} md={8}>
                <FormSection title="Fleet Map View">
                    <Grid item xs={12}>
                        <UniversalGoogleMap
                            fleetGps={fleetGps}
                            style={style}
                            mapZoom={6}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: -10 }}>
                        <UniversalDataTable
                            data={fleetTable}
                            visibleColumns={visibleColumns}
                            customColumnNames={customColumnNames}
                            onRowSelectionModelChange={(newSelection) => {
                                setState({
                                    activateData: newSelection,
                                })
                            }}
                            checkboxSelection={false}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormSection title="Fleet Status">
                    <Grid item xs={12} md={12}>
                        {telemetry && (
                            <SleepingPieChart
                                telemetry={telemetry.map((boat: any) => ({
                                    hin: boat._doc.fullHin,
                                    boatStatus: boat.redisObj._boatStatus,
                                }))}
                            />
                        )}
                    </Grid>
                </FormSection>
                <FormSection title="Fleet Models">
                    <Grid item xs={12} md={12}>
                        {telemetry && (
                            <ModelsBarGraph
                                telemetry={telemetry.map((boat: any) => ({
                                    hin: boat._doc.fullHin,
                                    model: boat._doc.boatModel.model,
                                }))}
                            />
                        )}
                    </Grid>
                </FormSection>
                {userRole === 'Super Admin' && (
                    <FormSection title="Fleet Manufacturer Count">
                        <Grid item xs={12} md={12}>
                            {telemetry && (
                                <ManufacturersChart telemetry={telemetry} />
                            )}
                        </Grid>
                    </FormSection>
                )}
            </Grid>
        </Grid>
    )
}

export default Home
